import AuthContext from '../utils/auth0/AuthContext'
import Button from '@material-ui/core/Button'
import { Dot } from 'react-animated-dots'
import Grid from '@material-ui/core/Grid'
import Head from '../components/informational/Head'
import LoadingStatus from '../components/informational/LoadingStatus'
import Logo from '../components/informational/Logo'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import empty from 'is-empty'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    height: '100vh',
    paddingTop: 100
  },
  content: {
    marginTop: 40,
    textAlign: 'center'
  }
}

class Callback extends React.Component {
  componentDidMount () {
    // TODO: needs to redirect if here by accident, or if here through auth0 dashboard
    this.context.handleCallbackPage()
  }

  render () {
    const { classes } = this.props
    const { authError, login } = this.context

    return (
      <>
        <Head />
        <Grid
          className={classes.root}
          container
          direction='column'
          justify='center'
          alignItems='center'
          spacing={0}
        >
          <Grid item xs={12}>
            <Logo />
            <div className={classes.content}>
              {empty(authError) &&
                <Typography variant='h4' align='center' color='textSecondary'>
                  <LoadingStatus loading loadingMessage={'Loading login'} readyMessage={'Login succeeded'} />
                  One moment please
                  <Dot>.</Dot>
                  <Dot>.</Dot>
                  <Dot>.</Dot>
                </Typography>
              }
              {!empty(authError) &&
                <>
                  <Typography variant='h4' align='center' color='textSecondary' gutterBottom>
                    {authError.errorDescription}
                  </Typography>
                  {authError.errorDescription.includes('verify your email') &&
                    <Button size='large' color='primary' onClick={() => login(false)}>
                      I have verified my email, continue login
                    </Button>
                  }
                  {!authError.errorDescription.includes('verify your email') &&
                    <Button size='large' color='primary' onClick={() => login(false)}>
                      Try to login again
                    </Button>
                  }
                </>
              }
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

Callback.contextType = AuthContext

export default withStyles(styles)(Callback)
